import {createSlice} from '@reduxjs/toolkit';
import {SIGN_PAGE} from "../../../../shared/utils/constants";
import {v4 as uuidv4} from 'uuid';
import {AppConfig} from "../../../../shared/model";
import {OauthClientAppConfig} from "../../../../shared/model";


export interface SponsorCenterAppState {
    displayPage: string;
    headers: any;
    appConfig: AppConfig;
    oauthClientAppConfig: OauthClientAppConfig;
    authJourneyData: any;
    userinfo: {
        isAuthenticated: boolean
    }
}

const sponsorCenterAppSlice = createSlice({
    name: 'sponsorCenterApp',
    initialState: {
        displayPage: SIGN_PAGE,
        headers: {
            'X-PruGlobalSessionID': uuidv4(),
        },
        appConfig: {},
        oauthClientAppConfig: {},
        authJourneyData: {},
        userinfo: {
            isAuthenticated: false
        }
    },
    reducers: {
        updateDisplayPage(state, action) {
            state.displayPage = action.payload;
        },
        updateAuthJourneyData(state, action) {
            state.authJourneyData = action.payload;
        },
        updateUserinfoState(state, action) {
            state.userinfo = action.payload;
        },
        updateAppConfig(state, action) {
            state.appConfig = action.payload;
        },
        updateOauthClientAppConfig(state, action) {
            state.oauthClientAppConfig = action.payload;
        },
    }
});

export const sponsorCenterAppActions = sponsorCenterAppSlice.actions;

export default sponsorCenterAppSlice;