export const SIGN_PAGE = 'SIGN_PAGE';

export const CHALLENGE_VERIFY_PAGE = 'CHALLENGE_VERIFY_PAGE';

export const CHALLENGE_RESEND_PAGE = 'CHALLENGE_RESEND_PAGE';

export const CHALLENGE_VERIFY_HRT_PAGE = 'CHALLENGE_VERIFY_HRT_PAGE';

export const CHALLENGE_RESEND_HRT_PAGE = 'CHALLENGE_RESEND_HRT_PAGE';

export const CHANGE_PASSWORD_EXPIRED = 'CHANGE_PASSWORD_EXPIRED';

export const DEFAULT_APP_AUTHORIZE_PATH = '/app/defaultApp/oauth2/authorize';

export const DEFAULT_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/defaultApp/challengeresend';

export const DEFAULT_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/defaultApp/challengeverify';

export const GI_APP_LOGIN_PATH = '/app/gi/login';

export const GI_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/gi/challengeresend';

export const GI_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/gi/challengeverify';

export const SPONSOR_CENTER_APP_LOGIN_PATH = '/app/sponsorcenter/login';

export const SPONSOR_CENTER_APP_REDIRECT_URI_PATH = '/app/sponsorcenter/oauth';

export const SPONSOR_CENTER_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/sponsorcenter/challengeresend';

export const SPONSOR_CENTER_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/sponsorcenter/challengeverify';

export const CHALLENGE_VERIFY_PAGE_PATH = 'challengeverify';

export const CHALLENGE_VALIDATION_CALLBACK = 'ChallengeValidationCallback';

export const CHALLENGE_GENERATION_CALLBACK = 'ChallengeGenerationCallback';

export const ACCOUNT_RECOVERY_PASSWORD_CALLBACK ='AccountRecoveryPasswordCallback';

export const SELF_SERVICE__HRT_CHANGE_PASSWORD_CALLBACK ='SelfServiceChangePasswordCallback';

export const RETIREMENT_SSWEB_APP_REDIRECT_URI_PATH = '/app/retirementssweb/oauth';

export const RETIREMENT_SSWEB_APP_LOGIN_PATH = '/app/retirementssweb/login';

export const RETIREMENT_SSWEB_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/retirementssweb/challengeresend';

export const RETIREMENT_SSWEB_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/retirementssweb/challengeverify';

export const ADVISOR_CENTER_APP_REDIRECT_URI_PATH = '/app/advisorcenter/oauth';

export const ADVISOR_CENTER_APP_LOGIN_PATH = '/app/advisorcenter/login';

export const ADVISOR_CENTER_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/advisorcenter/challengeresend';

export const ADVISOR_CENTER_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/advisorcenter/challengeverify';

export const RSC_APP_LOGIN_PATH = '/app/rsc/login';

export const UNIT_VALUES_APP_LOGIN_PATH = '/app/unitvalues/login';

export const PXB_LIFE_APP_LOGIN_PATH = '/app/pxblife/login';

export const PXB_LIFE_APP_REDIRECT_URI_PATH = '/app/pxblife/oauth';

export const PXB_LIFE_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/pxblife/challengeresend';

export const PXB_LIFE_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/pxblife/challengeverify';

export const PXB_LIFE_APP_CHANGE_PASSWORD_EXPIRED_PAGE_PATH = '/app/pxblife/recoverpasswordexpired';

export const QM_APP_LOGIN_PATH = '/app/qm/login';

export const QM_APP_REDIRECT_URI_PATH = '/app/qm/oauth';

export const QM_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/qm/challengeresend';

export const QM_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/qm/challengeverify';

export const CLO_APP_LOGIN_PATH = '/app/clo/login';

export const CLO_APP_REDIRECT_URI_PATH = '/app/clo/oauth';

export const CLO_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/clo/challengeresend';

export const CLO_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/clo/challengeverify';

export const PACCT_APP_LOGIN_PATH = '/app/pacct/login';

export const PACCT_APP_REDIRECT_URI_PATH = '/app/pacct/oauth';

export const PACCT_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/pacct/challengeresend';

export const PACCT_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/pacct/challengeverify';

export const FI_APP_LOGIN_PATH = '/app/fi/login';

export const FI_APP_REDIRECT_URI_PATH = '/app/fi/oauth';

export const FI_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/fi/challengeresend';

export const FI_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/fi/challengeverify';

export const PBF_APP_LOGIN_PATH = '/app/pbf/login';

export const PBF_APP_REDIRECT_URI_PATH = '/app/pbf/oauth';

export const PBF_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/pbf/challengeresend';

export const PBF_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/pbf/challengeverify';

export const PPC_APP_LOGIN_PATH = '/app/ppc/login';

export const PPC_APP_REDIRECT_URI_PATH = '/app/ppc/oauth';

export const PPC_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/ppc/challengeresend';

export const PPC_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/ppc/challengeverify';

export const PPA_APP_LOGIN_PATH = '/app/ppa/login';

export const PPA_APP_REDIRECT_URI_PATH = '/app/ppa/oauth';

export const PPA_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/ppa/challengeresend';

export const PPA_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/ppa/challengeverify';

export const JEN_APP_LOGIN_PATH = '/app/jen/login';

export const JEN_APP_REDIRECT_URI_PATH = '/app/jen/oauth';

export const JEN_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/jen/challengeresend';

export const JEN_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/jen/challengeverify';

export const PXB_ANNUITIES_APP_LOGIN_PATH = '/app/pxbAnnuities/login';

export const PXB_ANNUITIES_APP_REDIRECT_URI_PATH = '/app/pxbAnnuities/oauth';

export const PXB_ANNUITIES_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/pxbAnnuities/challengeresend';

export const PXB_ANNUITIES_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/pxbAnnuities/challengeverify';

export const ORACLE_EBS_APP_LOGIN_PATH = '/app/oracleEbs/login';

export const ORACLE_EBS_APP_REDIRECT_URI_PATH = '/app/oracleEbs/oauth';

export const ORACLE_EBS_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/oracleEbs/challengeresend';

export const ORACLE_EBS_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/oracleEbs/challengeverify';

export const SOAR_ADVISOR_JOURNEY_APP_LOGIN_PATH = '/app/soarAdvisorJourney/login';

export const SOAR_ADVISOR_JOURNEY_APP_REDIRECT_URI_PATH = '/app/soarAdvisorJourney/oauth';

export const SOAR_ADVISOR_JOURNEY_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/soarAdvisorJourney/challengeresend';

export const SOAR_ADVISOR_JOURNEY_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/soarAdvisorJourney/challengeverify';

export const COMMON_APP_LOGIN_PATH = '/app/common/login';

export const COMMON_APP_REDIRECT_URI_PATH = '/app/common/oauth';

export const COMMON_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/common/challengeresend';

export const COMMON_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/common/challengeverify';

export const COMMON_APP_CHANGE_PASSWORD_EXPIRED_PAGE_PATH = '/app/common/recoverpasswordexpired';

export const COMMON_APP_HRT_CHANGE_PASSWORD_PAGE_PATH = '/app/common/hrt/changepassword';

export const COMMON_APP_CHALLENGE_VERIFY_HRT_PAGE_PATH = '/app/common/hrt/challengeverify';

export const COMMON_APP_CHALLENGE_RESEND_HRT_PAGE_PATH = '/app/common/hrt/challengeresend';

export const PGIM_PRIVATE_CAPITAL_APP_LOGIN_PATH = '/app/pgimPrivateCapital/login';

export const PGIM_PRIVATE_CAPITAL_APP_REDIRECT_URI_PATH = '/app/pgimPrivateCapital/oauth';

export const PGIM_PRIVATE_CAPITAL_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/pgimPrivateCapital/challengeresend';

export const PGIM_PRIVATE_CAPITAL_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/pgimPrivateCapital/challengeverify';

export const PGIM_VENDOR_PORTAL_APP_LOGIN_PATH = '/app/pgimVendorPortal/login';

export const PGIM_VENDOR_PORTAL_APP_REDIRECT_URI_PATH = '/app/pgimVendorPortal/oauth';

export const PGIM_VENDOR_PORTAL_APP_CHALLENGE_RESEND_PAGE_PATH = '/app/pgimVendorPortal/challengeresend';

export const PGIM_VENDOR_PORTAL_APP_CHALLENGE_VERIFY_PAGE_PATH = '/app/pgimVendorPortal/challengeverify';

export const OIDC_TEMPLATE = "AUTHN_OIDC";

export const SESSION_TEMPLATE = "AUTHN";

export const CHANGE_PW_EXPIRED_TEMPLATE = "CHANGE_PW"

export const AUTHN_HRT_TEMPLATE = "AUTHN_HRT"

export const SCOPE = "openid";

export const RESPONSE_TYPE = "code";