import {configureStore} from "@reduxjs/toolkit";
import defaultAppSlice from "../pages/app/default/state/DefaultAppSlice";
import {loadStateFromLocalStorage} from "../shared/functions/localStorage";
import giAppSlice from "../pages/app/gi/state/GiAppSlice";
import sponsorCenterAppSlice from "../pages/app/sponsorcenter/state/SponsorCenterAppSlice";
import retirementSswebAppSlice from "../pages/app/ssweb/state/RetirementSswebAppSlice";
import advisorCenterAppSlice from "../pages/app/advisorcenter/state/AdvisorCenterAppSlice";
import pxbLifeAppSlice from "../pages/app/pxblife/state/PxbLifeAppSlice";
import rscAppSlice from "../pages/app/rsc/state/RscAppSlice";
import unitValuesAppSlice from "../pages/app/unitvalues/state/UnitValuesAppSlice";
import qmAppSlice from "../pages/app/qm/state/QmAppSlice";
import fiAppSlice from "../pages/app/fi/state/FiAppSlice";
import pacctAppSlice from "../pages/app/pacct/state/PacctAppSlice";
import cloAppSlice from "../pages/app/clo/state/CloAppSlice";
import pbfAppSlice from "../pages/app/pbf/state/PbfAppSlice";
import ppcAppSlice from "../pages/app/ppc/state/PpcAppSlice";
import ppaAppSlice from "../pages/app/ppa/state/PpaAppSlice";
import jenAppSlice from "../pages/app/jen/state/JenAppSlice";
import pxbAnnuitiesAppSlice from "../pages/app/pxbAnnuities/state/PxbAnnuitiesAppSlice";
import oracleEbsAppSlice from "../pages/app/oracleEbs/state/OracleEbsAppSlice";
import soarAdvisorJourneyAppSlice from "../pages/app/soarAdvisorJourney/state/SoarAdvisorJourneyAppSlice";
import commonAppSlice from "../pages/app/common/state/CommonAppSlice";
import pgimPrivateCapitalAppSlice from "../pages/app/pgimPrivateCaptial/state/PgimPrivateCapitalAppSlice";
import pgimVendorPortalAppSlice from "../pages/app/pgimVendorPortal/state/PgimVendorPortalAppSlice";

export const store = configureStore({
    devTools: true,
    reducer: {
        defaultApp: defaultAppSlice.reducer,
        giApp: giAppSlice.reducer,
        sponsorCenterApp: sponsorCenterAppSlice.reducer,
        retirementSswebApp: retirementSswebAppSlice.reducer,
        advisorCenterApp: advisorCenterAppSlice.reducer,
        commonApp: commonAppSlice.reducer,
        pxbLifeApp: pxbLifeAppSlice.reducer,
        ppaApp: ppaAppSlice.reducer,
        jenApp: jenAppSlice.reducer,
        rscApp: rscAppSlice.reducer,
        unitValuesApp: unitValuesAppSlice.reducer,
        pbfApp: pbfAppSlice.reducer,
        ppcApp: ppcAppSlice.reducer,
        qmApp: qmAppSlice.reducer,
        fiApp: fiAppSlice.reducer,
        pacctApp: pacctAppSlice.reducer,
        cloApp: cloAppSlice.reducer,
        pxbAnnuitiesApp: pxbAnnuitiesAppSlice.reducer,
        oracleEbsApp: oracleEbsAppSlice.reducer,
        soarAdvisorJourneyApp: soarAdvisorJourneyAppSlice.reducer,
        pgimPrivateCapitalApp: pgimPrivateCapitalAppSlice.reducer,
        pgimVendorPortalApp: pgimVendorPortalAppSlice.reducer
    },
    preloadedState: loadStateFromLocalStorage()
})