import { createTheme } from "@mui/material";

import HelveticaNeue from "./assets/fonts/HelveticaNeue.ttf";
import HelveticaNeueBold from "./assets/fonts/HelveticaNeueBold.ttf";

import Montserrat from "./assets/fonts/Montserrat.ttf";
import MontserratSemiBold from "./assets/fonts/MontserratSemiBold.ttf";
import MontserratBold from "./assets/fonts/MontserratBold.ttf";

export const primaryTheme = createTheme({
  spacing: 6, // Defines spacing values as a factor of 8px. Ex mr: 2 translates to margin-right: 8px * 2 = 16px
  palette: {
    primary: {
      main: "#007BC3",
      darkMain: "#001F45",
      blue: '#007BC3',
      white: '#FFFFFF',
      contrastText: "#fff",
      blackText: '#1a1d1e'
    },
    secondary: {
      main: "#ffd200",
      light: "#FFDD41"
    },
    tertiary: {
      light: "#8e9ee8",
      main: "#5d70b5",
      dark: "#2b4585",
      contrastText: "#fff",
    },
    pruColor: {
      electricViolet: "#5504E0",
      blueberry: "#5a80fb",
      lightBlue: "#edf1fd",
      lightPurple: "#5d70b5",
      lightPurpleBlue: "#DBE0EE",
      darkBlue: "#001f45",
      grey: "#ebeded",
      textBlack: '#1a1d1e',
      // Tints are intended to be used sparingly, primarily used in backgrounds paired with their core color.
      tints: {
        blue: '#007BC3',
        blueADA: '#0076D1',
        blueTint75: '#419CD3',
        blueTint50: '#7FBEE0'
      }
    },
    lightPattern: { //Finish this and change name
      light: "#fff",
      main: "#fff",
      dark: "#ccc",
      contrastingText: "#000"
    },
    dark: {
      light: "#344671",
      main: "#001f45",
      dark: "#00001f",
      800: 'rgb(21, 101, 192)',
      900: 'rgb(144, 202, 249)'
    },
    text: {
      primary: "#007BC3",
      secondary: "#344671",
      dark: "#001F45",
      black: '#1a1d1e',
      contrastText: "#fff",
      hint: 'rgb(30, 136, 229)',
      800: 'rgb(21, 101, 192)',
      200: 'rgb(144, 202, 249)'
    },
    grey:{
      200: 'rgb(21, 101, 192)',
      500: 'rgb(144, 202, 249)'
    },
    footer: {
      main: '#091f3d',
      contrastingText: 'hsla(0,0%,100%,.6)'
    }
  },
  typography: {
    h1: {
      fontSize: 46,
      fontFamily: "MontserratBold",
      fontWeight: "600",
    },
    h2: {
      fontSize: 38,
      fontFamily: "MontserratBold",
      fontWeight: "600",
    },
    h3: {
      fontSize: 28,
      fontFamily: "Montserrat",
      fontWeight: "600",
    },
    h4: {
      fontSize: 22,
      fontFamily: "Montserrat",
      fontWeight: "600",
    },
    h5: {
      fontSize: 18,
      fontFamily: "Montserrat",
      fontWeight: "600",
    },
    h6: {
      fontSize: 16,
      fontFamily: "Montserrat",
      fontWeight: "600",
    },
    body1: {
      fontSize: 15,
      fontFamily: "Montserrat",
      color: "#1A1D1E",
    },
    body2: {
      fontSize: 12,
      fontFamily: "Montserrat",
      color: "#1A1D1E",
    },
  },

  // TODO: Old way to handle spacing. Not correct format. To me removed later
  space: {
    one: "8px",
    two: "12px",
    three: "16px",
    four: "24px",
    five: "32px",
    six: "40px",
    seven: "52px",
    eight: "72px",
    nine: "96px",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
                font-family: "HelveticaNeue";
                src: url(${HelveticaNeue}) format('truetype');
                font-weight: normal;
            },
            @font-face {
                font-family: "HelveticaNeueBold";
                src: url(${HelveticaNeueBold}) format("truetype");
                font-weight: bold;
            },
            @font-face {
                font-family: "Montserrat";
                src: url(${Montserrat}) format("truetype");
                font-weight: normal;
            },
            @font-face {
                font-family: "MontserratBold";
                src: url(${MontserratBold}) format("truetype");
                font-weight: 700;
            },
            @font-face {
                font-family: "MontserratSemiBold";
                src: url(${MontserratSemiBold}) format("truetype");
                font-weight: "600";
            }
        `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Montserrat",
          fontWeight: 600,
          borderRadius: '4px',
        },
      },
    },
  },
});

export const globalStyles = {
  palette: {
    primary: {
      main: "#007BC3",
      darkMain: "#001F45",
      blue: '#007BC3',
      white: '#FFFFFF',
      contrastText: "#fff",
      blackText: '#1a1d1e'
    },
    secondary: {
      light: "#ffff52",
      main: "#FFD200",
      dark: "#c7a100",
      contrastText: "#000",
    },
    tertiary: {
      light: "#8e9ee8",
      main: "#5d70b5",
      dark: "#2b4585",
      contrastText: "#fff",
    },
    error: {
      primary: '#ff3333'
    },
    pruColor: {
      electricViolet: "#5504E0",
      blueberry: "#5a80fb",
      lightBlue: "#edf1fd",
      lightPurple: "#5d70b5",
      lightPurpleBlue: "#DBE0EE",
      darkBlue: "#001f45",
      grey: "#ebeded",
      textBlack: '#1a1d1e',
      // Tints are intended to be used sparingly, primarily used in backgrounds paired with their core color.
      tints: {
        blue: '#007BC3',
        blueTint75: '#419CD3',
        blueTint50: '#7FBEE0'
      }
    },
    lightPattern: { //Finish this and change name
      light: "#fff",
      main: "#fff",
      dark: "#ccc",
      contrastingText: "#000"
    },
    footer: {
      main: '#091f3d',
      contrastingText: 'hsla(0,0%,100%,.6)'
    }
  },
}