import {createSlice} from '@reduxjs/toolkit';
import {SIGN_PAGE} from "../../../../shared/utils/constants";
import {v4 as uuidv4} from 'uuid';
import {AppConfig} from "../../../../shared/model";


export interface GiAppState {
    displayPage: string;
    headers: any;
    appConfig: AppConfig;
    authJourneyData: any;
    userinfo: {
        isAuthenticated: boolean
    }
}

const giAppSlice = createSlice({
    name: 'giApp',
    initialState: {
        displayPage: SIGN_PAGE,
        headers: {
            'X-PruGlobalSessionID': uuidv4(),
        },
        appConfig: {},
        authJourneyData: {},
        userinfo: {
            isAuthenticated: false
        }
    },
    reducers: {
        updateDisplayPage(state, action) {
            state.displayPage = action.payload;
        },
        updateAuthJourneyData(state, action) {
            state.authJourneyData = action.payload;
        },
        updateUserinfoState(state, action) {
            state.userinfo = action.payload;
        },
        updateAppConfig(state, action) {
            state.appConfig = action.payload;
        }
    }
});

export const giAppActions = giAppSlice.actions;

export default giAppSlice;