import {createSlice} from '@reduxjs/toolkit';
import {SIGN_PAGE} from "../../../../shared/utils/constants";
import {v4 as uuidv4} from 'uuid';
import {OauthClientAppConfig} from "../../../../shared/model";



export interface DefaultAppState {
    displayPage: string;
    headers: any;
    oauthClientAppConfig: OauthClientAppConfig;
    authJourneyData: any;
    userinfo: {
        isAuthenticated: boolean
    }
}

const defaultAppSlice = createSlice({
    name: 'defaultApp',
    initialState: {
        displayPage: SIGN_PAGE,
        headers: {
            'X-PruGlobalSessionID': uuidv4(),
        },
        oauthClientAppConfig:{

        },
        authJourneyData: {},
        userinfo: {
            isAuthenticated: false
        }
    },
    reducers: {
        updateDisplayPage(state, action) {
            state.displayPage = action.payload;
        },
        updateAuthJourneyData(state, action) {
            state.authJourneyData = action.payload;
        },
        updateUserinfoState(state, action) {
            state.userinfo = action.payload;
        },
        updateOauthClientAppConfig(state, action) {
            state.oauthClientAppConfig = action.payload;
        }
    }
});

export const defaultAppActions = defaultAppSlice.actions;

export default defaultAppSlice;