
/*
    The functions here are meant to assist in keeping user state persistent
    meaning, when the user refreshes the page, or they need to halt for some
    reason, and they want to come back, their progress is saved.


*/

/*
    loadState()
    
    First checks to see if user has previously logged into the application.
    
    If they have, checks to see if their state session has expired. If it has,
    reload and re-route to application home.

    If not, grabs the state, parses it, and returns it.
 */
export const loadStateFromLocalStorage = () => {
    try {
        if (!checkForPreviousAppState()) { return undefined }

        if (checkForValidAppState()) {
            const serialState = localStorage.getItem('applicationState')
            let deserialized = JSON.parse(serialState);
            return deserialized.currentState;
        } else {
            return undefined
        }

    } catch (error) {
        return undefined
    }
}

export const saveStateInLocalStorage = (state) => {

    let serialize = { currentState: state }

    let stateAlreadyExists = checkForPreviousAppState();

    // New State

    if (!stateAlreadyExists) {
        serialize.stateExp = Date.now() + 1800000 // 30 minutes from now
        const serialState = JSON.stringify(serialize);
        localStorage.setItem('applicationState', serialState);
        return;
    }

    let stateValid = checkForValidAppState();

    if (stateAlreadyExists && !stateValid) {
        //reload page
    }

    serialize.stateExp = JSON.parse(localStorage.getItem('applicationState')).stateExp;

    const serialState = JSON.stringify(serialize);
    localStorage.setItem('applicationState', serialState);
}

export const purgeAppState = () => {
    const state = localStorage.getItem('applicationState');
    if (state) {
        localStorage.removeItem('applicationState');
    }
}

const checkForPreviousAppState = () => {
    const serialState = localStorage.getItem('applicationState');

    return serialState !== null;
}

const checkForValidAppState = () => {
    const deSerialState = JSON.parse(localStorage.getItem('applicationState'));

    if (!deSerialState) {
        return false
    }

    if (!deSerialState.stateExp) {
        return false
    }

    if (deSerialState.stateExp <= Date.now()) {
        purgeAppState();
        return false
    }

    return deSerialState.currentState.authX?.userinfo?.isAuthenticated;

}