import React, {lazy, Suspense} from 'react';
import './App.css';
import {store} from "./redux/store";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {
    SPONSOR_CENTER_APP_CHALLENGE_RESEND_PAGE_PATH, SPONSOR_CENTER_APP_CHALLENGE_VERIFY_PAGE_PATH,
    SPONSOR_CENTER_APP_LOGIN_PATH,
    DEFAULT_APP_AUTHORIZE_PATH,
    DEFAULT_APP_CHALLENGE_RESEND_PAGE_PATH,
    DEFAULT_APP_CHALLENGE_VERIFY_PAGE_PATH, GI_APP_CHALLENGE_RESEND_PAGE_PATH,
    GI_APP_CHALLENGE_VERIFY_PAGE_PATH,
    GI_APP_LOGIN_PATH,
    RETIREMENT_SSWEB_APP_CHALLENGE_RESEND_PAGE_PATH, RETIREMENT_SSWEB_APP_CHALLENGE_VERIFY_PAGE_PATH,
    RETIREMENT_SSWEB_APP_LOGIN_PATH, ADVISOR_CENTER_APP_LOGIN_PATH, ADVISOR_CENTER_APP_CHALLENGE_RESEND_PAGE_PATH,
    ADVISOR_CENTER_APP_CHALLENGE_VERIFY_PAGE_PATH, RSC_APP_LOGIN_PATH, UNIT_VALUES_APP_LOGIN_PATH,
    PXB_LIFE_APP_CHALLENGE_RESEND_PAGE_PATH, PXB_LIFE_APP_CHALLENGE_VERIFY_PAGE_PATH, PXB_LIFE_APP_LOGIN_PATH,
    PXB_LIFE_APP_CHANGE_PASSWORD_EXPIRED_PAGE_PATH, PBF_APP_LOGIN_PATH,PBF_APP_CHALLENGE_RESEND_PAGE_PATH, PBF_APP_CHALLENGE_VERIFY_PAGE_PATH,
    JEN_APP_CHALLENGE_RESEND_PAGE_PATH, JEN_APP_CHALLENGE_VERIFY_PAGE_PATH, JEN_APP_LOGIN_PATH,
    PPA_APP_CHALLENGE_RESEND_PAGE_PATH, PPA_APP_CHALLENGE_VERIFY_PAGE_PATH, PPA_APP_LOGIN_PATH,
    PPC_APP_CHALLENGE_RESEND_PAGE_PATH, PPC_APP_CHALLENGE_VERIFY_PAGE_PATH, PPC_APP_LOGIN_PATH,
    FI_APP_CHALLENGE_RESEND_PAGE_PATH, FI_APP_CHALLENGE_VERIFY_PAGE_PATH, FI_APP_LOGIN_PATH,
    CLO_APP_CHALLENGE_RESEND_PAGE_PATH, CLO_APP_CHALLENGE_VERIFY_PAGE_PATH, CLO_APP_LOGIN_PATH,
    PACCT_APP_CHALLENGE_RESEND_PAGE_PATH, PACCT_APP_CHALLENGE_VERIFY_PAGE_PATH, PACCT_APP_LOGIN_PATH,
    QM_APP_CHALLENGE_RESEND_PAGE_PATH, QM_APP_CHALLENGE_VERIFY_PAGE_PATH, QM_APP_LOGIN_PATH,
    PXB_ANNUITIES_APP_CHALLENGE_RESEND_PAGE_PATH, PXB_ANNUITIES_APP_CHALLENGE_VERIFY_PAGE_PATH,
    PXB_ANNUITIES_APP_LOGIN_PATH, ORACLE_EBS_APP_LOGIN_PATH,
    ORACLE_EBS_APP_CHALLENGE_RESEND_PAGE_PATH, ORACLE_EBS_APP_CHALLENGE_VERIFY_PAGE_PATH,
    SOAR_ADVISOR_JOURNEY_APP_CHALLENGE_VERIFY_PAGE_PATH,
    SOAR_ADVISOR_JOURNEY_APP_CHALLENGE_RESEND_PAGE_PATH,
    SOAR_ADVISOR_JOURNEY_APP_LOGIN_PATH, PGIM_PRIVATE_CAPITAL_APP_LOGIN_PATH, PGIM_PRIVATE_CAPITAL_APP_CHALLENGE_RESEND_PAGE_PATH,
    PGIM_PRIVATE_CAPITAL_APP_CHALLENGE_VERIFY_PAGE_PATH, PGIM_VENDOR_PORTAL_APP_CHALLENGE_RESEND_PAGE_PATH,
    PGIM_VENDOR_PORTAL_APP_LOGIN_PATH, PGIM_VENDOR_PORTAL_APP_CHALLENGE_VERIFY_PAGE_PATH
} from "./shared/utils/constants";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

const DefaultAppLogin = lazy(() => import("./pages/app/default/login/DefaultAppLogin"))
const DefaultAppChallengeVerify = lazy(() => import("./pages/app/default/challenge-verify/DefaultAppChallengeVerify"))
const DefaultAppChallengeResend = lazy(() => import("./pages/app/default/challenge-resend/DefaultAppChallengeResend"))
const GiAppLogin = lazy(() => import("./pages/app/gi/login/GiAppLogin"))
const GiAppChallengeVerify = lazy(() => import("./pages/app/gi/challenge-verify/GiAppChallengeVerify"))
const GiAppChallengeResend = lazy(() => import("./pages/app/gi/challenge-resend/GiAppChallengeResend"))
const SponsorCenterAppLogin = lazy(() => import("./pages/app/sponsorcenter/login/SponsorCenterAppLogin"))
const SponsorCenterAppChallengeVerify = lazy(() => import("./pages/app/sponsorcenter/challenge-verify/SponsorCenterAppChallengeVerify"))
const SponsorCenterAppChallengeResend = lazy(() => import("./pages/app/sponsorcenter/challenge-resend/SponsorCenterAppChallengeResend"))
const RetirementSswebAppLogin = lazy(() => import("./pages/app/ssweb/login/RetirementSswebAppLogin"))
const RetirementSswebAppChallengeVerify = lazy(() => import("./pages/app/ssweb/challenge-verify/RetirementSswebAppChallengeVerify"))
const RetirementSswebAppChallengeResend = lazy(() => import("./pages/app/ssweb/challenge-resend/RetirementSswebAppChallengeResend"))
const AdvisorCenterAppLogin = lazy(() => import("./pages/app/advisorcenter/login/AdvisorCenterAppLogin"))
const AdvisorCenterAppChallengeVerify = lazy(() => import("./pages/app/advisorcenter/challenge-verify/AdvisorCenterAppChallengeVerify"))
const AdvisorCenterAppChallengeResend = lazy(() => import("./pages/app/advisorcenter/challenge-resend/AdvisorCenterAppChallengeResend"))
const RscAppLogin = lazy(() => import("./pages/app/rsc/login/RscAppLogin"))
const UnitValuesAppLogin = lazy(() => import("./pages/app/unitvalues/login/UnitValuesAppLogin"))
const PxbLifeAppLogin = lazy(() => import("./pages/app/pxblife/login/PxbLifeAppLogin"))
const PxbLifeAppChallengeVerify = lazy(() => import("./pages/app/pxblife/challenge-verify/PxbLifeAppChallengeVerify"))
const PxbLifeAppChallengeResend = lazy(() => import("./pages/app/pxblife/challenge-resend/PxbLifeAppChallengeResend"))
const PxbLifeAppChangePasswordExpired = lazy(() => import("./pages/app/pxblife/change-password-expired/PxbLifeAppChangePasswordExpired"))
const FiAppLogin = lazy(() => import("./pages/app/fi/login/FiAppLogin"))
const FiAppChallengeVerify = lazy(() => import("./pages/app/fi/challenge-verify/FiAppChallengeVerify"))
const FiAppChallengeResend = lazy(() => import("./pages/app/fi/challenge-resend/FiAppChallengeResend"))
const CloAppLogin = lazy(() => import("./pages/app/clo/login/CloAppLogin"))
const CloAppChallengeVerify = lazy(() => import("./pages/app/clo/challenge-verify/CloAppChallengeVerify"))
const CloAppChallengeResend = lazy(() => import("./pages/app/clo/challenge-resend/CloAppChallengeResend"))
const PacctAppLogin = lazy(() => import("./pages/app/pacct/login/PacctAppLogin"))
const PacctAppChallengeVerify = lazy(() => import("./pages/app/pacct/challenge-verify/PacctAppChallengeVerify"))
const PacctAppChallengeResend = lazy(() => import("./pages/app/pacct/challenge-resend/PacctAppChallengeResend"))
const QmAppLogin = lazy(() => import("./pages/app/qm/login/QmAppLogin"))
const QmAppChallengeVerify = lazy(() => import("./pages/app/qm/challenge-verify/QmAppChallengeVerify"))
const QmAppChallengeResend = lazy(() => import("./pages/app/qm/challenge-resend/QmAppChallengeResend"))
const PbfAppLogin = lazy(() => import("./pages/app/pbf/login/PbfAppLogin"))
const PbfAppChallengeVerify = lazy(() => import("./pages/app/pbf/challenge-verify/PbfAppChallengeVerify"))
const PbfAppChallengeResend = lazy(() => import("./pages/app/pbf/challenge-resend/PbfAppChallengeResend"))
const JenAppLogin = lazy(() => import("./pages/app/jen/login/JenAppLogin"))
const JenAppChallengeVerify = lazy(() => import("./pages/app/jen/challenge-verify/JenAppChallengeVerify"))
const JenAppChallengeResend = lazy(() => import("./pages/app/jen/challenge-resend/JenAppChallengeResend"))
const PpaAppLogin = lazy(() => import("./pages/app/ppa/login/PpaAppLogin"))
const PpaAppChallengeVerify = lazy(() => import("./pages/app/ppa/challenge-verify/PpaAppChallengeVerify"))
const PpaAppChallengeResend = lazy(() => import("./pages/app/ppa/challenge-resend/PpaAppChallengeResend"))
const PpcAppLogin = lazy(() => import("./pages/app/ppc/login/PpcAppLogin"))
const PpcAppChallengeVerify = lazy(() => import("./pages/app/ppc/challenge-verify/PpcAppChallengeVerify"))
const PpcAppChallengeResend = lazy(() => import("./pages/app/ppc/challenge-resend/PpcAppChallengeResend"))
const PxbAnnuitiesAppLogin = lazy(() => import("./pages/app/pxbAnnuities/login/PxbAnnuitiesAppLogin"))
const PxbAnnuitiesAppChallengeVerify = lazy(() => import("./pages/app/pxbAnnuities/challenge-verify/PxbAnnuitiesAppChallengeVerify"))
const PxbAnnuitiesAppChallengeResend = lazy(() => import("./pages/app/pxbAnnuities/challenge-resend/PxbAnnuitiesAppChallengeResend"))
const OracleEbsAppLogin = lazy(() => import("./pages/app/oracleEbs/login/OracleEbsAppLogin"))
const OracleEbsAppChallengeVerify = lazy(() => import("./pages/app/oracleEbs/challenge-verify/OracleEbsAppChallengeVerify"))
const OracleEbsAppChallengeResend = lazy(() => import("./pages/app/oracleEbs/challenge-resend/OracleEbsAppChallengeResend"))
const SoarAdvisorJourneyAppLogin = lazy(() => import("./pages/app/soarAdvisorJourney/login/SoarAdvisorJourneyAppLogin"))
const SoarAdvisorJourneyAppChallengeVerify = lazy(() => import("./pages/app/soarAdvisorJourney/challenge-verify/SoarAdvisorJourneyAppChallengeVerify"))
const SoarAdvisorJourneyAppChallengeResend = lazy(() => import("./pages/app/soarAdvisorJourney/challenge-resend/SoarAdvisorJourneyAppChallengeResend"))
const PgimPrivateCapitalAppLogin = lazy(() => import("./pages/app/pgimPrivateCaptial/login/PgimPrivateCapitalAppLogin"))
const PgimPrivateCapitalAppChallengeVerify = lazy(() => import("./pages/app/pgimPrivateCaptial/challenge-verify/PgimPrivateCapitalAppChallengeVerify"))
const PgimPrivateCapitalAppChallengeResend = lazy(() => import("./pages/app/pgimPrivateCaptial/challenge-resend/PgimPrivateCapitalAppChallengeResend"))
const PgimVendorPortalAppLogin = lazy(() => import("./pages/app/pgimVendorPortal/login/PgimVendorPortalAppLogin"))
const PgimPVendorPortalAppChallengeVerify = lazy(() => import("./pages/app/pgimVendorPortal/challenge-verify/PgimVendorPortalAppChallengeVerify"))
const PgimVendorPortalAppChallengeResend = lazy(() => import("./pages/app/pgimVendorPortal/challenge-resend/PgimVendorPortalAppChallengeResend"))

//Protected route
const ProtectedRoute = lazy(() => import("./shared/auth/ProtectedRoute"))

store.subscribe(() => {

});


function App() {

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Router>
            <Suspense fallback={<Box sx={{
                display: "flex", justifyContent: "center", alignItems: "center", minHeight: '100vh', backgroundColor: "black", opacity: ".5"}}>
            <CircularProgress size={150} sx={{color: "white"}}/>
            </Box>}>

                <div className='App'>
                    <Routes>

                        {/* Sponsor Center App Routes Start */}

                        <Route path={SPONSOR_CENTER_APP_LOGIN_PATH} element={<>
                            <SponsorCenterAppLogin/>
                        </>}/>

                        <Route path={SPONSOR_CENTER_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <SponsorCenterAppChallengeResend/>
                        </>}/>

                        <Route path={SPONSOR_CENTER_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <SponsorCenterAppChallengeVerify/>
                        </>}/>

                        {/* Sponsor Center App Routes End */}

                        {/* Retirement SSWEB App Routes Start */}

                        <Route path={RETIREMENT_SSWEB_APP_LOGIN_PATH} element={<>
                            <RetirementSswebAppLogin/>
                        </>}/>

                        <Route path={RETIREMENT_SSWEB_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <RetirementSswebAppChallengeResend/>
                        </>}/>

                        <Route path={RETIREMENT_SSWEB_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <RetirementSswebAppChallengeVerify/>
                        </>}/>

                        {/* Retirement SSWEB App Routes End */}

                        {/* Advisor Center App Routes Start */}

                        <Route path={ADVISOR_CENTER_APP_LOGIN_PATH} element={<>
                            <AdvisorCenterAppLogin/>
                        </>}/>

                        <Route path={ADVISOR_CENTER_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <AdvisorCenterAppChallengeResend/>
                        </>}/>

                        <Route path={ADVISOR_CENTER_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <AdvisorCenterAppChallengeVerify/>
                        </>}/>

                        {/*Advisor Center App Routes End */}

                        {/*RSC App Route Start */}

                        <Route path={RSC_APP_LOGIN_PATH} element={<>
                            <RscAppLogin/>
                        </>}/>

                        {/*RSC App Route End */}

                        {/*Unit Values App Route Start */}

                        <Route path={UNIT_VALUES_APP_LOGIN_PATH} element={<>
                            <UnitValuesAppLogin/>
                        </>}/>

                        {/*Unit Values App Route End */}

                        {/*PxbLife App Routes Start */}

                        <Route path={PXB_LIFE_APP_LOGIN_PATH} element={<>
                            <PxbLifeAppLogin/>
                        </>}/>

                        <Route path={PXB_LIFE_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <PxbLifeAppChallengeResend/>
                        </>}/>

                        <Route path={PXB_LIFE_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <PxbLifeAppChallengeVerify/>
                        </>}/>

                        <Route path={PXB_LIFE_APP_CHANGE_PASSWORD_EXPIRED_PAGE_PATH} element={<ProtectedRoute>
                            <PxbLifeAppChangePasswordExpired/>
                        </ProtectedRoute>}/>
                        {/*Pxb Life App Routes End */}

                        {/*Fi App Routes Start */}

                        {/* <Route path={FI_APP_LOGIN_PATH} element={<>
                            <FiAppLogin/>
                        </>}/>

                        <Route path={FI_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <FiAppChallengeResend/>
                        </>}/>

                        <Route path={FI_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <FiAppChallengeVerify/>
                        </>}/> */}

                        {/*Fi App Routes End */}
                        {/*Pacct App Routes Start */}

                        {/* <Route path={PACCT_APP_LOGIN_PATH} element={<>
                            <PacctAppLogin/>
                        </>}/>

                        <Route path={PACCT_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <PacctAppChallengeResend/>
                        </>}/>

                        <Route path={PACCT_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <PacctAppChallengeVerify/>
                        </>}/> */}

                        {/*Pacct Life App Routes End */}

                        {/*CLO App Routes Start */}

                        {/* <Route path={CLO_APP_LOGIN_PATH} element={<>
                            <CloAppLogin/>
                        </>}/>

                        <Route path={CLO_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <CloAppChallengeResend/>
                        </>}/>

                        <Route path={CLO_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <CloAppChallengeVerify/>
                        </>}/> */}

                        {/*CLO App Routes End */}

                        {/*Qm App Routes Start */}

                        {/* <Route path={QM_APP_LOGIN_PATH} element={<>
                            <QmAppLogin/>
                        </>}/>

                        <Route path={QM_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <QmAppChallengeResend/>
                        </>}/>

                        <Route path={QM_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <QmAppChallengeVerify/>
                        </>}/> */}

                        {/*Qm App Routes End */}

                        {/*Pbf App Routes Start */}

                        {/* <Route path={PBF_APP_LOGIN_PATH} element={<>
                            <PbfAppLogin/>
                        </>}/>

                        <Route path={PBF_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <PbfAppChallengeResend/>
                        </>}/>

                        <Route path={PBF_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <PbfAppChallengeVerify/>
                        </>}/> */}

                        {/*Pbf App Routes End */}
                        {/*Jen App Routes Start */}

                        {/* <Route path={JEN_APP_LOGIN_PATH} element={<>
                            <JenAppLogin/>
                        </>}/>

                        <Route path={JEN_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <JenAppChallengeResend/>
                        </>}/>

                        <Route path={JEN_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <JenAppChallengeVerify/>
                        </>}/> */}

                        {/*Jen App Routes End */}

                        {/*Ppa App Routes Start */}

                        {/* <Route path={PPA_APP_LOGIN_PATH} element={<>
                            <PpaAppLogin/>
                        </>}/>

                        <Route path={PPA_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <PpaAppChallengeResend/>
                        </>}/>

                        <Route path={PPA_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <PpaAppChallengeVerify/>
                        </>}/> */}

                        {/*Ppa App Routes End */}

                        {/*Ppc App Routes Start */}

                        {/* <Route path={PPC_APP_LOGIN_PATH} element={<>
                            <PpcAppLogin/>
                        </>}/>

                        <Route path={PPC_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <PpcAppChallengeResend/>
                        </>}/>

                        <Route path={PPC_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <PpcAppChallengeVerify/>
                        </>}/> */}

                        {/*Ppc App Routes End */}

                        {/*PxbAnnuities App Routes Start */}

                        {/* <Route path={PXB_ANNUITIES_APP_LOGIN_PATH} element={<>
                            <PxbAnnuitiesAppLogin/>
                        </>}/>

                        <Route path={PXB_ANNUITIES_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <PxbAnnuitiesAppChallengeResend/>
                        </>}/>

                        <Route path={PXB_ANNUITIES_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <PxbAnnuitiesAppChallengeVerify/>
                        </>}/>  */}

                        {/*PxbAnnuities App Routes End */}

                        {/*OracleEbs App Routes Start */}

                        {/* <Route path={ORACLE_EBS_APP_LOGIN_PATH} element={<>
                            <OracleEbsAppLogin/>
                        </>}/>

                        <Route path={ORACLE_EBS_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <OracleEbsAppChallengeResend/>
                        </>}/>

                        <Route path={ORACLE_EBS_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <OracleEbsAppChallengeVerify/>
                        </>}/> */}

                        {/*OracleEbs App Routes End */}


                        {/*SoarAdvisorJourney App Routes Start */}
{/*
                        <Route path={SOAR_ADVISOR_JOURNEY_APP_LOGIN_PATH} element={<>
                            <SoarAdvisorJourneyAppLogin/>
                        </>}/>

                        <Route path={SOAR_ADVISOR_JOURNEY_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <SoarAdvisorJourneyAppChallengeResend/>
                        </>}/>

                        <Route path={SOAR_ADVISOR_JOURNEY_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <SoarAdvisorJourneyAppChallengeVerify/>
                        </>}/> */}

                        {/*SoarAdvisorJourney App Routes End */}

                         {/*PgimPrivateCapital App Routes Start */}

                         {/* <Route path={PGIM_PRIVATE_CAPITAL_APP_LOGIN_PATH} element={<>
                            <PgimPrivateCapitalAppLogin/>
                        </>}/>

                        <Route path={PGIM_PRIVATE_CAPITAL_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <PgimPrivateCapitalAppChallengeResend/>
                        </>}/>

                        <Route path={PGIM_PRIVATE_CAPITAL_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <PgimPrivateCapitalAppChallengeVerify/>
                        </>}/> */}

                        {/*PgimPrivateCapital App Routes End */}

                        {/*PgimVendorPortal App Routes Start */}

                        {/* <Route path={PGIM_VENDOR_PORTAL_APP_LOGIN_PATH} element={<>
                            <PgimVendorPortalAppLogin/>
                        </>}/>

                        <Route path={PGIM_VENDOR_PORTAL_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <PgimVendorPortalAppChallengeResend/>
                        </>}/>

                        <Route path={PGIM_VENDOR_PORTAL_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <PgimPVendorPortalAppChallengeVerify/>
                        </>}/> */}

                        {/*PgimVendorPortal App Routes End */}

                        {/* Default App Routes Start */}

                        {/* <Route path={DEFAULT_APP_AUTHORIZE_PATH} element={<>
                            <DefaultAppLogin/>
                        </>}/>

                        <Route path={DEFAULT_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <DefaultAppChallengeVerify/>
                        </>}/>

                        <Route path={DEFAULT_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <DefaultAppChallengeResend/>
                        </>}/> */}

                        {/* Default App Routes End */}

                        {/* GI App Routes Start */}

                        {/* <Route path={GI_APP_LOGIN_PATH} element={<>
                            <GiAppLogin/>
                        </>}/>

                        <Route path={GI_APP_CHALLENGE_RESEND_PAGE_PATH} element={<>
                            <GiAppChallengeResend/>
                        </>}/>

                        <Route path={GI_APP_CHALLENGE_VERIFY_PAGE_PATH} element={<>
                            <GiAppChallengeVerify/>
                        </>}/> */}

                        {/* GI App Routes End */}

                    </Routes>
                </div>
                </Suspense>
            </Router>
        </LocalizationProvider>

    );
}

export default App;